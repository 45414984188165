<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

//import NumberInputSpinner from "vue-number-input-spinner";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import axios from 'axios';

import {
  required,
  requiredIf,
  //email,
  //sameAs,
  minLength,
  maxLength,
  //minValue,
  //maxValue,
  //numeric,
  //url,
  //alphaNum
} from "vuelidate/lib/validators";

/**
 * Form validation component
 */
export default {
  page: {
    title: "User registation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, FormWizard, TabContent, DatePicker },
  props:{
    id:[Number,null],
  },
  data() {
    return {
      title:                        "Evaluation Creation",
      form: {
        id_company:                 "",
        id_site:                    "",
        id_department:              "",
        id_evaluationType:          "",
        id_userCreator:             "",
        dt_initialDate:             "",
        dt_finalDate:               "",
        responsibleEvaluator:       [],
        employeesToBeEvaluated:     [],
        colleagues:                 [],
        employeesToEvaluate:        [],
        categoriesToEvaluate:       [],
      },
      filterOn:                     [],
      fields:                       [],
      selected:                     [],
      employees:                    [],
      employeesToBeEvaluated:       [],
      colleagues:                   [],
      nm_evaluation:                "",
      sortBy:                       "",
      filterResponsible:            null,
      filterEmployeesTBEvaluated:   null,
      filterEmployeesToEvaluate:    null,
      filterCategories:             null,
      filterColleagues:             null,
      submitted:                    false,
      sortDesc:                     false,
      totalRows:                    1,
      currentPage:                  1,
      perPage:                      100,
      pageOptions:                  [100],
      selectMode:                   ['single', 'multi', 'range'],
      options:                      { format: 'YYYY-MM-DD', },
      items: [
        { text: "Evaluations",  href: "/" },
        { text: "Evaluations",  href: "/Evaluations/evaluations" },
        { text: "Evaluation",   active: true }
      ],
      categories: [
        {id_category:1,   nm_category:'PERSONAL SKILLS'},
        {id_category:2,   nm_category:'LEADERSHIP'},
        {id_category:3,   nm_category:'CONTINUOUS IMPROVEMENT'},
        {id_category:4,   nm_category:'PLANNING AND ORGANIZATION'},
        {id_category:5,   nm_category:'SOLVING PROBLEMS'},
        {id_category:6,   nm_category:'COMMUNICATION'},
        {id_category:7,   nm_category:'AUTONOMY'},
        {id_category:8,   nm_category:'ENTERPRISE KNOWLEDGE'},
        {id_category:9,   nm_category:'CUSTOMER FOCUS'},
        {id_category:10,  nm_category:'INITIATIVE'},
        {id_category:11,  nm_category:'OBJECTIVE RESULTS'},
        {id_category:12,  nm_category:'DECISION MAKING'},
      ],
      categoriesField: [
        {key:'id_category', label:'',       sortable:true},
        {key:'nm_category', label:'CATEGORY', sortable:true},
        {key:'actions',     label:'ACTIONS',  tdClass: "actionsFixed"}
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.employees.length;
    }
  },
  validations: {
    form: {
      id_evaluationType:      { required },
      responsibleEvaluator:   { required, maxLength: maxLength(1) },
      employeesToBeEvaluated: { required, minLength: minLength(1) },
      colleagues:             {
        required: requiredIf(function(){ return this.form.id_evaluationType>=2 && this.form.id_evaluationType<=4; }),
        minLength: minLength(1),
      },
      employeesToEvaluate:    {
        required: requiredIf(function(){ return this.form.id_evaluationType>=3 && this.form.id_evaluationType<=4; }),
        minLength: minLength(1),
      },
      categoriesToEvaluate:   { required, minLength: minLength(1) },
      dt_initialDate:         { required },
      dt_finalDate:           { required },
    }
  },
  watch:{},
  created(){
    if(localStorage){
      this.user = JSON.parse( localStorage.getItem('user') );
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows            = 0;
    this.form.id_company      = this.user.user.id_company;
    this.form.id_site         = this.user.user.id_site;
    this.form.id_department   = this.user.user.id_department;
    this.form.id_userCreator  = this.user.user.id_user;

    if(this.user.user.id_role >4 ){
      this.$router.push({ name:  'Error-401' });
    }

    if( this.$route.params.id ){
      this.getEvaluation();
    }

    this.getFields();
    this.getEmployeesForLog();
  },
  methods: {
    //Get the columns name of the employees table to be showed up.
    async getFields(){
      let app = this;
      axios
      .get(
        'http://localhost/dist/mvw/controllers/employees.php',
        {
          params: {
            request: 'getFields',
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          app.fields = response.data;
          app.sortBy = response.data[0].key;
        }else{
          Swal.fire("Ooops!", "It was nor possible to load data! Please load this module again.", "error");
        }
      }).catch(e =>{
        Swal.close();
        if( e.response.status == 500){ app.$router.push({ name: 'Error-500' }); }
        if( e.response.status == 404){ app.$router.push({ name: 'Error-404' }); }
      });
    },

    //Get the list of employees availables to be selected wheter to be an evaluator, or to be evaluated.
    async getEmployeesForLog(){
      Swal.fire({
        title: "Loading employees.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        'http://localhost/dist/mvw/controllers/employees.php',
        {
          params: {
            request:    'getEmployeesForLog',
            idCompany:  this.user.user.id_company,
            idLevel:    this.user.user.id_levelJobPosition,
            op:         'le'
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          Swal.close();
          if(response.data.error){
            Swal.fire("Ooops!", response.data.error, "error");
          }else{
            this.employees = response.data;  
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    //Get the list of colleagues to evaluate Responsible of the evaluation.
    async getColleagues(){
      Swal.fire({
        title: "Loading colleagues.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        'http://localhost/dist/mvw/controllers/employees.php',
        {
          params: {
            request:    'getEmployeesForLog',
            idCompany:  this.user.user.id_company,
            idLevel:    this.user.user.id_levelJobPosition,
            idJobTitle: this.user.user.id_jobTitle,
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          Swal.close();
          if(response.data.error){
            Swal.fire("Ooops!", response.data.error, "error");
          }else{
            this.colleagues = response.data;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    //Get the list of colleagues to evaluate Responsible of the evaluation.
    async getEmployees(){
      Swal.fire({
        title: "Loading employees.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        'http://localhost/dist/mvw/controllers/employees.php',
        {
          params: {
            request:    'getEmployeesForLog',
            idCompany:  this.form.responsibleEvaluator[0].id_company,
            idManager:  this.form.responsibleEvaluator[0].id_employee,
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          Swal.close();
          if(response.data.error){
            Swal.fire("Ooops!", response.data.error, "error");
          }else{
            this.employeesToBeEvaluated = response.data;
          }
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    async getEvaluation(){
      Swal.fire({
        title: "Loading evaluation data.",
        showConfirmButton:false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      axios
      .get(
        'http://localhost/dist/mvw/controllers/evaluations.php',
        {
          params: {
            request:  'getEvaluationByID',
            id:       this.$route.params.id
          }
        }
      )
      .then(response => {
        if( response.statusText == "OK" ){
          this.evaluation = response.data;

          this.form.id_evaluationType       = this.evaluation.id_evaluationType;
          this.form.dt_initialDate          = this.evaluation.dt_initialDate;
          this.form.dt_finalDate            = this.evaluation.dt_finalDate;
          this.form.id_site                 = this.evaluation.id_site;
          this.form.id_department           = this.evaluation.id_department;

          this.form.responsibleEvaluator    = [
            {
              id_company: this.evaluation.id_company,
              id_site: this.evaluation.id_site,
              id_department: this.evaluation.id_department,
              id_responsibleEvaluator: this.evaluation.id_responsibleEvaluator,
              id_employee: this.evaluation.id_responsibleEvaluator,
            }
          ];

          this.form.employeesToBeEvaluated  = this.evaluation.id_employeesToBeEvaluated;
          this.form.colleagues              = this.evaluation.id_colleaguesEvaluators;
          this.form.employeesToEvaluate     = this.evaluation.id_employeesEvaluators;
          this.form.categoriesToEvaluate    = this.evaluation.id_categories;

          this.getEmployees();
          if(this.form.id_evaluationType >= 2 && this.form.id_evaluationType <=4 ){
            this.getColleagues();
          }
          
          Swal.close();
        }else{
          Swal.fire("Ooops!", "It was nor possible to load Countries! Please load this module again.", "error");
        }
      });
    },

    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    /**
     * Search the table data with search input
     */
    onFilteredResponsible(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredColleagues(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredEmployeesTBEvaluated(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredEmployeesToEvaluateResponsible(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFilteredCategories(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },


    //Gets the text of the selected evaluation.
    getEvaluationName(event){
      this.nm_evaluation = event.target.options[event.target.options.selectedIndex].text;
    },

    //Stores the Responsible Evaluator or clean it.
    onResponsibleEvaluatorSelected(items) {
      var index = items.map((item) => this.form.responsibleEvaluator.indexOf(item));

      if(items.length == 0){
        this.form.responsibleEvaluator          = [];
        index = null;
      }else{
        this.form.responsibleEvaluator[0]       = items[0];
        this.form.responsibleEvaluator[0].index = index[0];
      }
    },

    //Stores selected employees to be evaluated or clean them.
    onColleaguesSelected(items){
      //var index = items.map((item) => this.form.responsibleEvaluator.indexOf(item));
      //console.log(index);

      if(items.length == 0){
        this.form.colleagues = [];
      }else{
        this.form.colleagues = items;
      }
    },

    //Stores selected employees to be evaluated or clean them.
    onEmployeesToBeEvaluatedSelected(items){
      if(items.length == 0){
        this.form.employeesToBeEvaluated = [];
      }else{
        this.form.employeesToBeEvaluated = items;
      }
    },

    onEmployeesToEvaluateSelected(items){
      if(items.length == 0){
        this.form.employeesToEvaluate = [];
      }else{
        this.form.employeesToEvaluate = items;
      }
    },

    //Stores the selecte categories to be evaluated.
    onCategoriesSelected(items){
      if( items.length == 0 ){
        this.form.categoriesToEvaluate = [];
      }else{
        this.form.categoriesToEvaluate = items;
      }
    },

    selectIndividualRow(array, reference, idToCheck){
      let index = 0;
      array.forEach(function(item){
        if(Array.isArray(idToCheck)){
          idToCheck.forEach(function(id){
            if(id == item.id_employee){
              reference.selectRow(index);
            }
          });
        }else{
          if(idToCheck == item.id_employee){
            reference.selectRow(index);
          }
        }
        index++;
      });
    },
    selectIndividualCategory(){
      let app = this;
      let index = 0;
      this.categories.forEach(function(category){
        app.form.categoriesToEvaluate.forEach(function(cat){
          if(cat == category.id_category){
            app.$refs.Categories.selectRow(index);
          }
        });
        index++;
      });
    },

    /**
     * ON BEFORE-CHANGE FUNCTIONS
     * **/
    checkEvaluationType(){
      if( this.$route.params.id ){
        this.selectIndividualRow(this.employees, this.$refs.Responsible, this.form.responsibleEvaluator[0].id_responsibleEvaluator);
        this.selectIndividualCategory();
        return true
      }else{
        this.$v.$reset();
        if(this.form.id_evaluationType != "" && this.form.dt_initialDate != "" && this.form.dt_finalDate != ""){
          return true;
        }else{
          this.$v.$touch();
          Swal.fire("Ooops!","Please full fill this section.", "error");
        }
        return false;
      }
    },
    checkResponsibleEvaluator(){
      if( this.$route.params.id ){
        this.selectIndividualRow(this.employeesToBeEvaluated, this.$refs.EmployeesToBeEvaluated, this.form.employeesToBeEvaluated);
        if(this.form.colleagues.length > 0 ){
          this.selectIndividualRow(this.colleagues, this.$refs.Colleagues, this.form.colleagues);
        }
        if(this.form.employeesToEvaluate > 0 ){
          this.selectIndividualRow(this.employeesToBeEvaluated, this.$refs.EmployeesToEvaluate, this.form.employeesToEvaluate);
        }
        return true;
      }else{
        this.$v.$reset();
        if( this.form.responsibleEvaluator.length > 0 ){
          this.getEmployees();
          if(this.form.id_evaluationType >= 2 && this.form.id_evaluationType <=4 ){
            this.getColleagues();
          }
          return true;
        }else{
          this.$v.$touch();
          Swal.fire("Ooops!","Please choose the Responsible Evaluator.", "error");
        }
      }

      return false;
    },
    checkColleagues(){
      this.$v.$reset();
      if(this.form.colleagues.length > 0){
        return true
      }else{
        this.$v.$touch();
        Swal.fire("Ooops!","Please choose at least 1 Colleague to evaluate partner.", "error");
      }
      
      return false;
    },
    checkEmployeesToEvaluate(){
      if(this.form.employeesToEvaluate.length > 0){
        return true;
      }else{
        this.$v.$touch();
        Swal.fire("Ooops!","Please choose at least 1 person to evaluate the Responsible Evaluator.", "error");
      }
      return false;
    },
    checkEmployeesToBeEvaluated(){
      if(this.form.employeesToBeEvaluated.length > 0){
        return true;
      }else{
        this.$v.$touch();
        Swal.fire("Ooops!","Please choose at least 1 employee to be evaluated.", "error");
      }
      return false;
    },
    checkCategoriesToBeEvaluated(){
      if( this.form.categoriesToEvaluate.length > 0 ){
        return true;
      }else{
        this.$v.$touch();
        Swal.fire("Ooops!","Please choose at least 1 category to be evaluated.", "error");
      }
      return false;
    },


    /**
     * ON ROW CLICKED FUNCTIONS
    **/
    onResponsibleClicked(item, index){
      //console.log(index);
      if( item.id_employee === this.form.responsibleEvaluator[0].id_employee ){
        this.$refs.EmployeesToBeEvaluated.selectRow(index);
        Swal.fire("Ooops!","You can't choose Responsible Evaluation.", "error");
      }
    },
    onColleaguesClicked(item, index){
      if( item.id_employee === this.form.responsibleEvaluator[0].id_employee ){
        this.$refs.Colleagues.selectRow(index);
        Swal.fire("Ooops!","You can't choose Responsible Evaluation.", "error");
      }
    },
    onEmployeesToEvaluateClicked(item, index){
      if( item.id_employee === this.form.responsibleEvaluator[0].id_employee ){
        this.$refs.EmployeesToEvaluate.selectRow(index);
        Swal.fire("Ooops!","You can't choose Responsible Evaluation.", "error");
      }
    },

    // eslint-disable-next-line no-unused-vars
    onComplete: function() {
      let app = this;

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      
      if( !this.$v.form.$error ){
        Swal.fire({
          title: "Saving evaluation.",
          showConfirmButton:false,
          onBeforeOpen: () => {
            Swal.showLoading();
          }
        });

        if( this.$route.params.id ){
          this.form.id_evaluation = this.$route.params.id;
        }

        axios.post('http://localhost/dist/mvw/controllers/evaluations.php', {
          request: 'saveEvaluation',
          data: this.form, 
        })
        .then(function (response) {
          let action = "";
          if( response.data === "OK" ){
            action = "saved";
          }
          if( response.data === "UPDATED" ){
            action = "updated";
          }

          Swal.fire("Good job!", "Evaluation is correct "+action+"!", "success");
          app.$router.push({ name: 'evaluations' });
        })
        .catch(function (error) {
          Swal.fire("Ooops!" + error.message, "Seems like some error ocurr, please try again.", "error");
        });
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <!--<form class="needs-validation">-->
              <div class="row">
                <div class="col-lg-12">
                  <form-wizard color="#556ee6" @on-complete="onComplete">
                    <h4 class="card-title" slot="title"></h4>

                    <tab-content
                      icon="far fa-file-alt"
                      title="Choose Evaluation Type"
                      :before-change="checkEvaluationType"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group row mb-3">
                            <label for="id_evaluationType">Evaluation Type</label>
                            <select
                              class="form-control"
                              :class="{ 'is-invalid': $v.form.id_evaluationType.$error }"
                              name="id_evaluationType"
                              id="id_evaluationType"
                              v-model="form.id_evaluationType"
                              v-on:change="getEvaluationName"
                            >
                              <option value="">SELECT EVALUATION TYPE</option>
                              <option value="1">90° EVALUATION</option>
                              <option value="2">180° EVALUATION</option>
                              <option value="3">270° EVALUATION</option>
                              <option value="4">360° EVALUATION</option>
                              <!--<option value="5">DEPARTMENTAL JOB ENVIRONMENT</option>-->
                            </select>
                            <div v-if="$v.form.id_evaluationType.$error" class="invalid-feedback">
                              <span v-if="!$v.form.id_evaluationType.required">Gender is required.</span>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group mb-3">
                                <label for="dt_employeeHireDate">Initial Date</label>
                                <br />
                                <date-picker
                                  v-model="form.dt_initialDate"
                                  :first-day-of-week="1"
                                  lang="en"
                                  :class="{ 'is-invalid': $v.form.dt_initialDate.$error }"
                                  name="dt_initialDate"
                                  id="dt_initialDate"
                                  valueType="format"
                                  format="YYYY-MM-DD"
                                  :disabled-date="notBeforeToday"
                                  :value="form.dt_initialDate"
                                >
                                </date-picker>
                                <div v-if="$v.form.dt_initialDate.$error" class="invalid-feedback">
                                  <span v-if="!$v.form.dt_initialDate.required">Initial date is required.</span>
                                </div>
                              </div>
                            </div>

                            <div class="col-lg-6">
                              <div class="form-group mb-3">
                                <label for="dt_finalDate">Final Date</label>
                                <br />
                                <date-picker
                                  v-model="form.dt_finalDate"
                                  :first-day-of-week="1"
                                  lang="en"
                                  :class="{ 'is-invalid': $v.form.dt_finalDate.$error }"
                                  name="dt_finalDate"
                                  id="dt_finalDate"
                                  valueType="format"
                                  format="YYYY-MM-DD"
                                  :disabled-date="notBeforeToday"
                                  :value="form.dt_finalDate"
                                >
                                </date-picker>
                                <div v-if="$v.form.dt_finalDate.$error" class="invalid-feedback">
                                  <span v-if="!$v.form.dt_finalDate.required">Final date is required.</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>
                        <!-- end col -->
                      </div>
                      <!-- end row -->
                    </tab-content>

                    <tab-content
                      v-if="form.id_evaluationType"
                      icon="mdi mdi-face-profile"
                      title="Choose Responsible Evaluator"
                      :before-change="checkResponsibleEvaluator"
                    >
                      <div class="row">
                        <div class="col-12">
                          <h4 class="card-title"></h4>
                          
                          <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                              <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                  Show&nbsp;
                                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                </label>
                              </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                  Search:
                                  <b-form-input
                                    v-model="filterResponsible"
                                    type="search"
                                    placeholder="Search..."
                                    class="form-control form-control-sm ml-2"
                                  ></b-form-input>
                                </label>
                              </div>
                            </div>
                            <!-- End search -->
                          </div>

                          <!-- Table -->
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="employees"
                              :fields="fields"
                              responsive="sm"
                              :per-page="perPage"
                              :current-page="currentPage"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filterResponsible"
                              :filter-included-fields="filterOn"
                              @filtered="onFilteredResponsible"
                              :hover="true"
                              selectable
                              :select-mode="selectMode[0]"
                              @row-selected="onResponsibleEvaluatorSelected"
                              ref="Responsible"
                            >
                              <template #cell(actions)="{ rowSelected }">
                                <template v-if="rowSelected">
                                  <i class="fas fa-check btn-sm btn-primary"></i> <b>SELECTED</b>
                                </template>
                                <template v-else>
                                  <i class="fas fa-check btn-sm btn-light"></i>
                                </template>
                              </template>
                              <!--
                              <template v-slot:cell(actions)="row">
                                
                                
                                 info(row.item, row.index, $event.target) 
                              </template>
                              -->
                            </b-table>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                  <!-- pagination -->
                                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end col -->
                      </div>
                      <!-- end row -->
                    </tab-content>

                    <tab-content
                      v-if="form.id_evaluationType>=2 && form.id_evaluationType<=4"
                      icon="mdi mdi-account-multiple"
                      title="Choose Colleagues Evaluator"
                      :before-change="checkColleagues"
                    >
                      <div class="row">
                        <div class="col-12">
                          <h4 class="card-title"></h4>
                          
                          <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                              <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                  Show&nbsp;
                                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                </label>
                              </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                  Search:
                                  <b-form-input
                                    v-model="filterColleagues"
                                    type="search"
                                    placeholder="Search..."
                                    class="form-control form-control-sm ml-2"
                                  ></b-form-input>
                                </label>
                              </div>
                            </div>
                            <!-- End search -->
                          </div>

                          <!-- Table -->
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="colleagues"
                              :fields="fields"
                              responsive="sm"
                              :per-page="perPage"
                              :current-page="currentPage"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filterColleagues"
                              :filter-included-fields="filterOn"
                              @filtered="onFilteredColleagues"
                              :hover="true"
                              selectable
                              :select-mode="selectMode[0]"
                              @row-selected="onColleaguesSelected"
                              @row-clicked="onColleaguesClicked"
                              ref="Colleagues"
                            >
                              <template #cell(actions)="{ rowSelected }">
                                <template v-if="rowSelected">
                                  <i class="fas fa-check btn-sm btn-primary"></i> <b>SELECTED</b>
                                </template>
                                <template v-else>
                                  <i class="fas fa-check btn-sm btn-light"></i>
                                </template>
                              </template>
                              <!--
                              <template v-slot:cell(actions)="row">
                                
                                
                                 info(row.item, row.index, $event.target) 
                              </template>
                              -->
                            </b-table>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                  <!-- pagination -->
                                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end col -->
                      </div>
                      <!-- end row -->
                    </tab-content>
                    
                    <tab-content
                      v-if="form.id_evaluationType>=3 && form.id_evaluationType <=4"
                      icon="mdi mdi-account-voice"
                      title="Choose Personnel to Evaluate Responsible Evaluator"
                      :before-change="checkEmployeesToEvaluate"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                              <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                  Show&nbsp;
                                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                </label>
                              </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                  Search:
                                  <b-form-input
                                    v-model="filterEmployeesToEvaluate"
                                    type="search"
                                    placeholder="Search..."
                                    class="form-control form-control-sm ml-2"
                                  ></b-form-input>
                                </label>
                              </div>
                            </div>
                            <!-- End search -->
                          </div>

                          <!-- Table -->
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="employeesToBeEvaluated"
                              :fields="fields"
                              responsive="sm"
                              :per-page="perPage"
                              :current-page="currentPage"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filterEmployeesToEvaluate"
                              :filter-included-fields="filterOn"
                              @filtered="onFilteredEmployeesTBEvaluated"
                              :hover="true"
                              selectable
                              :select-mode="selectMode[1]"
                              @row-selected="onEmployeesToEvaluateSelected"
                              @row-clicked="onEmployeesToEvaluateClicked"
                              ref="EmployeesToEvaluate"
                            >
                              <template #cell(actions)="{ rowSelected }">
                                <template v-if="rowSelected">
                                  <i class="fas fa-check btn-sm btn-primary"></i> <b>SELECTED</b>
                                </template>
                                <template v-else>
                                  <i class="fas fa-check btn-sm btn-light"></i>
                                </template>
                              </template>
                              <!--
                              <template v-slot:cell(actions)="row">
                                
                                
                                 info(row.item, row.index, $event.target) 
                              </template>
                              -->
                            </b-table>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                  <!-- pagination -->
                                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end col -->
                      </div>
                      <!-- end row -->
                    </tab-content>

                    <tab-content
                      v-if="form.id_evaluationType"
                      icon="mdi mdi-account-group"
                      title="Choose Employees to be Evaluated"
                      :before-change="checkEmployeesToBeEvaluated"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                              <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                  Show&nbsp;
                                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                </label>
                              </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                  Search:
                                  <b-form-input
                                    v-model="filterEmployeesTBEvaluated"
                                    type="search"
                                    placeholder="Search..."
                                    class="form-control form-control-sm ml-2"
                                  ></b-form-input>
                                </label>
                              </div>
                            </div>
                            <!-- End search -->
                          </div>

                          <!-- Table -->
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="employeesToBeEvaluated"
                              :fields="fields"
                              responsive="sm"
                              :per-page="perPage"
                              :current-page="currentPage"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filterEmployeesTBEvaluated"
                              :filter-included-fields="filterOn"
                              @filtered="onFilteredEmployeesTBEvaluated"
                              :hover="true"
                              selectable
                              :select-mode="selectMode[1]"
                              @row-selected="onEmployeesToBeEvaluatedSelected"
                              @row-clicked="onResponsibleClicked"
                              ref="EmployeesToBeEvaluated"
                            >
                              <template #cell(actions)="{ rowSelected }">
                                <template v-if="rowSelected">
                                  <i class="fas fa-check btn-sm btn-primary"></i> <b>SELECTED</b>
                                </template>
                                <template v-else>
                                  <i class="fas fa-check btn-sm btn-light"></i>
                                </template>
                              </template>
                              <!--
                              <template v-slot:cell(actions)="row">
                                
                                
                                 info(row.item, row.index, $event.target) 
                              </template>
                              -->
                            </b-table>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                  <!-- pagination -->
                                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end col -->
                      </div>
                      <!-- end row -->
                    </tab-content>

                    <tab-content
                      v-if="form.id_evaluationType"
                      icon="mdi mdi-tag-multiple"
                      title="Choose Categoires to be Evaluated"
                      :before-change="checkCategoriesToBeEvaluated"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                              <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                  Show&nbsp;
                                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                </label>
                              </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                <label class="d-inline-flex align-items-center">
                                  Search:
                                  <b-form-input
                                    v-model="filterCategories"
                                    type="search"
                                    placeholder="Search..."
                                    class="form-control form-control-sm ml-2"
                                  ></b-form-input>
                                </label>
                              </div>
                            </div>
                            <!-- End search -->
                          </div>

                          <!-- Table -->
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="categories"
                              :fields="categoriesField"
                              responsive="sm"
                              :per-page="perPage"
                              :current-page="currentPage"
                              :sort-by.sync="sortBy"
                              :sort-desc.sync="sortDesc"
                              :filter="filterCategories"
                              :filter-included-fields="filterOn"
                              @filtered="onFilteredCategories"
                              :hover="true"
                              selectable
                              :select-mode="selectMode[1]"
                              @row-selected="onCategoriesSelected"
                              ref="Categories"
                            >
                              <template #cell(actions)="{ rowSelected }">
                                <template v-if="rowSelected">
                                  <i class="fas fa-check btn-sm btn-primary"></i> <b>SELECTED</b>
                                </template>
                                <template v-else>
                                  <i class="fas fa-check btn-sm btn-light"></i>
                                </template>
                              </template>
                              <!--
                              <template v-slot:cell(actions)="row">
                                
                                
                                 info(row.item, row.index, $event.target) 
                              </template>
                              -->
                            </b-table>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                  <!-- pagination -->
                                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end col -->
                      </div>
                      <!-- end row -->
                    </tab-content>

                    <tab-content
                      v-if="form.id_evaluationType"
                      icon="mdi mdi-checkbox-marked-circle-outline"
                      title="Confirm setup"
                    >
                      <div class="row">
                        <div class="col-12">
                          <div class="text-center">
                            <h2 class="mt-0">
                              <i class="mdi mdi-check-all"></i>
                            </h2>
                            <h3 class="mt-0">You did it !</h3>

                            <p class="w-75 mb-2 mx-auto">
                              Evaluation setup is completed, now you just have to confirm meta data of the evaluation:
                              <br><br>
                              <ul>
                                <li v-if="nm_evaluation">Evaluation: <b>{{nm_evaluation}}</b>.</li>
                                <li v-if="form.responsibleEvaluator[0]">Responsible Evaluation: <b>{{(form.responsibleEvaluator[0].nm_employee)}}</b>.</li>
                                <li v-if="form.colleagues.length > 0"><b>{{form.colleagues.length}}</b> Colleages will evaluate performance of {{form.responsibleEvaluator[0].nm_employee}}</li>
                                <li v-if="form.employeesToEvaluate.length > 0"><b>{{form.employeesToEvaluate.length}}</b> Employees will evaluate performance of {{form.responsibleEvaluator[0].nm_employee}}</li>
                                <li v-if="form.categoriesToEvaluate.length > 0"><b>{{form.categoriesToEvaluate.length}}</b> Categories to be evaluated.</li>
                                <li><b>{{form.employeesToBeEvaluated.length}}</b> employees to be evaluated.</li>
                              </ul>
                            </p>

                            <div class="mb-3">
                              <div class="custom-control custom-checkbox">
                                <input id="customCheck1" type="checkbox" class="custom-control-input" />
                                <label
                                  class="custom-control-label"
                                  for="customCheck1"
                                >I agree with the Terms and Conditions</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end col -->
                      </div>
                      <!-- end row -->
                    </tab-content>

                    <button type="button" class="btn btn-primary" slot="prev">Back</button>
                    <button type="button" class="btn btn-primary" slot="next">Next</button>
                    <button type="button" class="btn btn-success" slot="finish">Finish</button>
                  </form-wizard>
                </div>
              </div>
            <!--</form>-->
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<style type="text/css">
  .is-invalid  .mx-input{
    border: solid 1px red !important;
  }
  .form-control:disabled{
    background-color: #ecf0f1 !important;
  }
</style>